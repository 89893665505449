.cardDiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align items to the start */
    align-items: flex-start; /* Align items to the start */
    margin: 10px;
    padding: 10px;
    border: 0px solid rgb(255, 255, 255);
    border-radius: 10px;
    background-color: rgb(0, 89, 0); /* Change background color to green */
    background: linear-gradient(to right, rgb(0, 89, 0), transparent);
    width: 400px; /* Set a fixed width */

    overflow: hidden; /* Hide overflow */
    color: white; /* Change text color to white */
}
.cardDiv p {
    margin: 0; /* Remove default margin */
}
