@import './global.css';

html, body {

  margin: 0;
  background-color: var(--bkColour);
}
body {
  padding-top: 60px; /* This should be equal to your navbar's height */
}
.App {
  text-align: center;

}
.App-logos {
  height: 40vmin;
  pointer-events: none;
}
.App-logo {
  height: 20px;
  pointer-events: none;
  
}
.theMainDiv {
  margin-top: 0px;
  
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 0.8s linear;
  }
}



.App-header {
  background-color: hwb(211 2% 22%);
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  color: white;
}
.App-header h1 {
  font-size: 2em;
  margin: 0;
  padding: 0;
}
.App-header p {
  
  margin: 30px;
  padding: 0;
}

.App-link {
  color: #61dafb;
}

.ticker {
  position: fixed;
  height: 40px;
  align-content: center;
  bottom: 0;
  width: 100%;
  background: #000;
  color: #fff;
  overflow: hidden;
}

.ticker-text {
  display: inline-block;
  white-space: nowrap;
  font-size: 20px;
  padding-left: 100%;
  animation: ticker 20s linear infinite;
}
.ticker-text span {
  display: inline-block;
  padding-left:10px;
}
.ticker-text:hover {
  animation-play-state: paused;
}

.framerticker {
  position: fixed;
  height: 40px;
  align-content: center;
  bottom: 0;
  width: 100%;
  background: #000;
  color: #fff;
  overflow: hidden;
}

.framerticker-text {
  display: inline-block;
  white-space: nowrap;
  font-size: 20px;
  padding-left: 0%;
  background-color: #61dafb;
}

@keyframes ticker {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
