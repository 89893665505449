/* About.module.css */
@import './global.css';
.page {
    
    justify-content: center;
    align-items: center;
    
    
    
    background-color: var(--bkColour);
}
.container {
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.content {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
}