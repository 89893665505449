nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #DABD4D;
    height: 55px;
    position: fixed; /* Add this line */
  top: 0; /* Add this line */
  width: 100%; /* Add this line */
  box-sizing: border-box; /* Add this line */
  }
  
  .logo {
    display: flex;
    align-items: center;
    line-height: 1.5rem;
    font-size: 1.5rem;
  }
  
  .nav-links {
    display: flex;
    list-style: none;
  }
  
  .nav-links a {
    margin-left: 2rem;
    text-decoration: none;
  }
  
  .burger {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  
  .burger div {
    width: 2rem;
    height: 0.25rem;
    background-color: #333;
  }
  
  @media (max-width: 768px) {
    .nav-links {
      display: none;
    }
  
    .burger {
      display: flex;
    }
  
    .show-nav {
      display: flex;
      flex-direction: column;
    }
  }